import React, { useState } from "react";
import Container from "react-bootstrap/Container";

import HamburgerIcon from "../../UI/HamburgerMenu/HamburgerIcon";
import arrowTop from "../../../assets/arrow-up-icon/arrow-up.png";

import "./SideDrawer.css";
// import Backdrop from "../../UI/Backdrop/Backdrop";

const SideDrawer = (props) => {
  const [showSideDrawer, setShowSideDrawer] = useState(false);
  // const [sideDrawerMinimise, setSideDrawerMinimise] = useState(false);

  let attachedClasses = showSideDrawer
    ? ["sideDrawerContainer", "show"]
    : props.sideDrawerRetract
    ? ["sideDrawerContainer", "hide", "minimised"]
    : ["sideDrawerContainer", "hide", "original"];

  const toggleMenuModalHandler = () => {
    setShowSideDrawer(!showSideDrawer);
  };

  // const scrollActionHandler = (e) => {
  //   let element = e.target;
  //   console.log("Scroll event detected:", element);

  //   if (element.scrollHeight - element.scrollTop === element.clientHeight) {
  //     setSideDrawerMinimise(true);
  //     console.log(`window.scrollY = ${window.scrollY}`);
  //   }
  // if (window.scrollY > 128) {
  //   setSideDrawerMinimise(true);
  //   console.log(`window.scrollY = ${window.scrollY}`);
  // }
  // };

  const navMenu = showSideDrawer ? (
    <div className="menuContainer">
      <a href="/" onClick={toggleMenuModalHandler}>
        Home
      </a>
      <a href="#services" onClick={toggleMenuModalHandler}>
        Services
      </a>
      <a href="#about" onClick={toggleMenuModalHandler}>
        About
      </a>
      <a href="#contacts" onClick={toggleMenuModalHandler}>
        Contacts
      </a>
    </div>
  ) : null;

  // Use overlay element to remove side drawer if user clicks outside of the menu element ...
  const overlayElement = showSideDrawer ? (
    <div className="overlay" onClick={toggleMenuModalHandler}></div>
  ) : null;

  return (
    // <div onScroll={scrollActionHandler}>
    <>
      <Container className={attachedClasses.join(" ")}>
        <HamburgerIcon toggleMenuModal={toggleMenuModalHandler} />
        {navMenu}
      </Container>

      {/* Top navigation tab ... */}
      <Container className="navTop">
        <a href="#top">
          <img src={arrowTop} alt="" />
        </a>
      </Container>

      {overlayElement}
    </>
    // </div>
  );
};

export default SideDrawer;

// import React, { useState } from "react";
import React from "react";
import Container from "react-bootstrap/Container";
import Card from "react-bootstrap/Card";
// import ListGroup from "react-bootstrap/ListGroup";

// import OverlayTrigger from "react-bootstrap/OverlayTrigger";
// import Tooltip from "react-bootstrap/Tooltip";

import "./Services.css";
// import Portfolios from "../Portfolios/Portfolios";
import businessIcon from "../../assets/businessIllustration-cropped.png";
import techIcon from "../../assets/icons8-tech-illustration-conifer-chip.png";
// import industryIcon from "../../assets/icons8-industry-illustration-geom-4.png";
import industryIcon from "../../assets/icons8-tech-skills-illustration-crayon-page-under-construction-cropped.png";

// serviceObj contains higher level services (with specific skills listed under relevant portfolio items)
const serviceObj = [
  {
    id: "business",
    title: "Business Strategy",
    icon: businessIcon,
    iconClassname: "businessIcon",
    introTxt: "Leverage effective analysis frameworks.",
    services: [
      "Innovation feasibility analysis",
      "Tech-startup strategies",
      "Investor pitch decks",
      "Business plan synthesis",
      "Financial projections / modelling",
      "Organisation & goals re-alignment",
      "Operations analysis & optimisation",
      "Growth & transformation strategies",
    ],
  },
  {
    id: "technical",
    title: "Technology Development",
    icon: techIcon,
    iconClassname: "techIcon",
    introTxt: "Deep-tech insight yields an unfair advantage.",
    services: [
      "Web & Mobile application development",
      "UX / UI prototyping",
      "Data analysis & presentation",
      "Agile team management",
      "Systems design",
      "Cloud implementations & migration",
      "Microchip design strategies",
      "Hardware Vs. software partitioning",
    ],
  },
  {
    id: "industry",
    title: "Industry Exposure",
    icon: industryIcon,
    iconClassname: "industryIcon",
    introTxt: "Extensive and diverse industry experience.",
    services: [
      "Digital media authentication",
      "Digital communications",
      "Access control for digital TV",
      "Technical and business consulting",
      "Data encryption",
      "Optical communications",
      "Health services, including tele-health",
      "Internet & cyber security",
    ],
  },
];

const Services = (props) => {
  // const [modalActive, setmodalActive] = useState(false);
  // const [donutSelector, setDonutSelector] = useState("business");

  // const modalHandler = (id) => {
  //   setmodalActive(true);
  //   setDonutSelector(id);
  // };

  // const renderTooltip = (props) => (
  //   <Tooltip id="services-tooltip" {...props}>
  //     Click for more detail
  //   </Tooltip>
  // );

  const cardItems =
    serviceObj.length !== 0
      ? serviceObj.map((item) => (
          <Card
            className="servCard"
            key={item.id}
            // onClick={() => modalHandler(item.id)}
          >
            <Card.Title as="h3">{item.title}</Card.Title>
            <Card.Text as="h4">{item.introTxt}</Card.Text>
            {/* <OverlayTrigger
              placement="right-end"
              delay={{ show: 250, hide: 10 }}
              overlay={renderTooltip}
              defaultShow="true"
              trigger={["hover", "focus"]}
            > */}
            <Card.Img
              variant="top"
              src={item.icon}
              className={item.iconClassname}
            />
            {/* </OverlayTrigger> */}

            <Card.Body>
              {item.services.length !== 0
                ? item.services.map((service, index) => (
                    <p className="serviceItem" key={index}>
                      {service}
                    </p>
                  ))
                : null}
            </Card.Body>
            {/* <button className="portfolioButton">More detail</button> */}
          </Card>
        ))
      : null;

  const classNameString = props.show ? "section" : "section noDisplay";

  return (
    <div className={classNameString} id="services">
      <h2>Services</h2>
      <Container className="servicesContainer">{cardItems}</Container>
      {/* <Portfolios
        show={modalActive}
        onHide={() => setmodalActive(false)}
        type={donutSelector}
      /> */}
    </div>
  );
};

export default Services;

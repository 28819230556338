import React from "react";
import "./HamburgerIcon.css";
import Container from "react-bootstrap/Container";

const HamburgerIcon = (props) => {
  return (
    <Container fluid className="iconContainer" onClick={props.toggleMenuModal}>
      <Container className="iconElement" />
      <Container className="iconElement" />
      <Container className="iconElement" />
    </Container>
  );
};

export default HamburgerIcon;

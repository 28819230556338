import React, { useState } from "react";
import Container from "react-bootstrap/Container";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import ProgressBar from "react-bootstrap/ProgressBar";

import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";

// import profileImg from "../../assets/sketch-portrait.jpg";
// import profileImg from "../../assets/portrait-ah0.jpg";
import profileImg from "../../assets/portrait-ah1.jpg";
import eagleImg from "../../assets/BateleurLandingUnedited.jpg";

import Education from "../Education/Education";
// import Portfolios from "../Portfolios/Portfolios";

import aboutTextFile from "./About.json";

import "./About.css";
import eagleIllustration from "../../assets/icons8-eagleFact-looney-dove.png";

const AboutPage = (props) => {
  const [showProfileAH, setShowProfileAH] = useState(false);
  // const [showQualsAH, setShowQualsAH] = useState(false);
  const [showProfileEagle, setShowProfileEagle] = useState(false);

  const { bbcaText, ahProfileText, eagleProfileText, portfolioObject } =
    JSON.parse(JSON.stringify(aboutTextFile));

  if (
    bbcaText.length < 1 ||
    ahProfileText.length < 1 ||
    eagleProfileText.length < 1
  ) {
    console.log("Problem encountered while reading About.json config file.");
    console.log(
      `bbcaText length = ${bbcaText.length}\nahProfileText length = ${ahProfileText.length}\neagleProfileText length = ${eagleProfileText.length}`
    );
  }

  // const qualifications = showQualsAH ? <Education /> : null;

  const classNameString = props.show ? "section" : "section noDisplay";
  const classNameStringAH = showProfileAH ? "section" : "sectionNoBackground";

  const initialView = (
    <>
      <h2>About us</h2>
      <h4>Bateleur Business Consulting Australasia</h4>
      <div className="bbcaText txtBlockSpaced">
        {bbcaText.length > 0
          ? bbcaText.map((txtItem, index) => <p key={index}>{txtItem}</p>)
          : null}
      </div>
    </>
  );

  const ahProfileView = (
    <div className={classNameStringAH} id="aboutAllenId">
      <div className="ahProfileControls">
        <img
          src={profileImg}
          alt="About Allen Hirsch"
          className="ahProfileImg"
        />
        <button
          id="profileAH"
          className={showProfileAH ? "visible" : null}
          variant="primary"
          onClick={() => setShowProfileAH(!showProfileAH)}>
          {`${showProfileAH ? "Hide" : "Show"} Allen's details`}
        </button>
      </div>

      {showProfileAH ? (
        <Container className="aboutAllenContainer txtBlockSpaced" fluid>
          <div>
            {/* <h4>Allen Hirsch </h4> */}

            <Tabs
              defaultActiveKey="profile"
              // id="uncontrolled-tab-example"
              className="mb-3">
              <Tab eventKey="profile" title="Profile" id="profileTab">
                {ahProfileText.length > 0
                  ? ahProfileText.map((txtItem, index) => (
                      <p key={index}>{txtItem}</p>
                    ))
                  : null}
              </Tab>

              {portfolioObject.length > 0
                ? portfolioObject.map((item, index) => (
                    <Tab
                      eventKey={item.id}
                      title={item.title}
                      key={index}
                      id={item.id + "Tab"}>
                      <div>
                        {item.attribute.map((attr, arrIndex) => {
                          return (
                            <div className="progressItem" key={arrIndex}>
                              {attr.label}
                              <ProgressBar
                                className="progressBar"
                                now={attr.value}
                                animated
                                variant={item.colorVariant}
                              />
                            </div>
                          );
                        })}
                      </div>
                      {/* <Portfolios data={item} /> */}
                    </Tab>
                  ))
                : null}

              <Tab
                eventKey="qualifications"
                title="Qualifications"
                id="qualsTab">
                <Education />
              </Tab>
            </Tabs>
          </div>
        </Container>
      ) : null}
    </div>
  );

  const eagleProfileView = (
    <>
      {!showProfileEagle ? (
        <div
          className="eagleProfileControls"
          id="eagleProfileContainer"
          onClick={() => setShowProfileEagle(!showProfileEagle)}>
          <p>Read about the Bateleur eagle</p>
          <img
            id="eagleProfileImg"
            src={eagleIllustration}
            alt=""
            className="eagleIcon"
          />
        </div>
      ) : null}

      <Modal
        // {...props}
        show={showProfileEagle}
        // aria-labelledby="contained-modal-title-vcenter"
        // fullscreen='lg-down'
        size="xl"
        centered
        onHide={() => setShowProfileEagle(!showProfileEagle)}>
        <Modal.Header closeButton>
          <h4 className="modalTitle">Introducing the Bateleur eagle</h4>
        </Modal.Header>
        <Modal.Body>
          <Container className="eagleProfileContainer txtBlockSpaced">
            <img
              src={eagleImg}
              alt="Bateleur eagle introduction"
              className="eagleProfileImg"
            />
            {eagleProfileText.length > 0
              ? eagleProfileText.map((txtItem, index) => (
                  <p key={index}>{txtItem}</p>
                ))
              : null}
          </Container>
        </Modal.Body>
        <Modal.Footer className="modalFooter">
          <Button
            style={{ alignSelf: "center" }}
            onClick={() => setShowProfileEagle(!showProfileEagle)}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );

  return (
    <>
      <div className={classNameString} id="about">
        {initialView}
        {ahProfileView}
      </div>

      {eagleProfileView}
    </>
  );
};

export default AboutPage;

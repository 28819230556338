import React from "react";
// import { Switch, Route, NavLink } from "react-router-dom";

import Layout from "./hoc/Layout/Layout";
import Services from "./components/Services/Services";
import About from "./components/About/About";
import Contacts from "./components/Contacts/Contacts";

import "./App.css";

function App() {
  return (
    <div className="App">
      <Layout>
        <Services show />
        <About show />
        <Contacts show />
      </Layout>
    </div>
  );
}

export default App;

import React from "react";
// import { connect } from 'react-redux'

import classes from "./Layout.module.css";
// import Toolbar from "../../components/Navigation/Toolbar/Toolbar";

import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";

const Layout = (props) => {
  return (
    <div className={classes.Content}>
      <Header />
      <main>{props.children}</main>
      <Footer />
    </div>
  );
};

export default Layout;

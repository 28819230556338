// import React, { useState } from "react";
import React from "react";

import bbcaHeaderLogo from "../../assets/BBCA header logo.png";

import SideDrawer from "../Navigation/SideDrawer/SideDrawer";
import "./Header.css";

const Header = () => {
  // const [sideDrawerRetract, setSideDrawerRetract] = useState(false);

  // const onScrollHandler = (e) => {
  //   console.log(
  //     `Scroll event triggered ... window.scrollY = ${window.scrollY}`
  //   );
  //   let element = e.target;
  //   if (element.scrollHeight - element.scrollTop === element.clientHeight) {
  //     setSideDrawerRetract(true);
  //   }
  // };

  // const sideDrawerRetract = window.scrollY > 128;

  return (
    <div className="wrapper">
      <div className="appHeader" id="top">
        <a href="/">
          <img
            className="headerLogo"
            src={bbcaHeaderLogo}
            alt="Bateleur Business and Technology Consulting Australasia"
            onScroll={(e) => {
              console.log("onScroll triggered ...\n");
            }}
          />
        </a>
        <SideDrawer
          // sideDrawerRetract={sideDrawerRetract}
          className="sideDrawer"
        />
      </div>
    </div>
  );
};

export default Header;

import React from "react";

import Card from "react-bootstrap/Card";
import bbcaLogo from "../../assets/BBCA header logo.png";
// import phoneIcon from "../../assets/icon8-phone.svg";
import webIcon from "../../assets/icons8-globe-48.png";
// import contactUsIllustration from "../../assets/icons8-contactUs-pixeltrue-contact.svg";
// import contactUsIllustration from "../../assets/icons8-aboutUs-coming-soon-4.png";

import "./Contacts.css";

const Contacts = (props) => {
  const classNameString = props.show ? "section" : "section noDisplay";

  return (
    <div className={classNameString} id="contacts">
      <h2>Contact Info.</h2>

      <Card className="contactCard">
        <Card.Body className="contactCardBody">
          <Card.Img
            variant="top"
            src={bbcaLogo}
            className="contactsMainImage"
          />
          {/* Removed mobile contact no for now ...
            <p className="contactDetail highlight">
            <img src={phoneIcon} alt="" className="icon8 phoneIcon" />
            <span style={{ fontSize: "0.85em" }}>(+61) 0414 714 071</span>
          </p> */}
          <p className="contactDetail  highlight">
            <a
              id="linkedInContact"
              href="https://www.linkedin.com/in/allenhirsch"
              target="_blank"
              rel="noreferrer">
              <img
                className="icon8 linkedInIcon"
                alt=""
                src="https://img.icons8.com/doodle/48/000000/linkedin--v2.png"
              />
              linkedin.com/in/allenhirsch
            </a>
          </p>
          <p className="contactDetail highlight">
            <a id="mailContact" href="mailto:info@bateleurbizconsult.com.au">
              <img
                className="icon8 emailIcon"
                alt=""
                src="https://img.icons8.com/doodle/50/000000/new-post.png"
              />
              info@bateleurbizconsult.com.au
            </a>
          </p>
          <p className="contactDetail highlight">
            <img
              className="icon8 webicon"
              // src="https://img.icons8.com/clouds/100/000000/domain.png"
              src={webIcon}
              alt=""
            />
            www.bateleurbizconsult.com.au
          </p>
          <p className="contactDetail">
            <img
              className="icon8 addressIcon"
              alt=""
              src="https://img.icons8.com/doodle/50/000000/address.png"
            />

            <span className="deEmphasise">
              PO Box 195, Maroubra, NSW, 2035, Australia
            </span>
          </p>
          <p className="contactDetail deEmphasise">ABN 65 374 098 363</p>
        </Card.Body>
      </Card>
    </div>
  );
};

export default Contacts;

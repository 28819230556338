import React from "react";
import Container from "react-bootstrap/Container";
import Card from "react-bootstrap/Card";

import "./Education.css";
import witsLogo from "../../assets/wits-logo.svg";
import agsmLogo from "../../assets/agsm-logo.png";
import unswLogo from "../../assets/unsw-logo.png";

const educationArray = [
  {
    id: "agsm",
    show: true,
    name: "Australian Graduate School of Management",
    address: "Sydney, Australia",
    logoUrl: agsmLogo,
    websiteUrl: "https://www.unsw.edu.au/business/agsm",
    qualification: "MBA (Executive)",
    startDate: "",
    endDate: "2010",
  },

  {
    id: "unsw",
    show: false,
    name: "University of New South Wales",
    address: "Sydney, Australia",
    logoUrl: unswLogo,
    websiteUrl: "https://www.unsw.edu.au",
    qualification: "",
    startDate: "",
    endDate: "",
  },

  {
    id: "wits",
    show: true,
    name: "University of the Witwatersrand",
    address: "Johannesburg, South Africa",
    logoUrl: witsLogo,
    websiteUrl: "https://www.wits.ac.za",
    qualification: "BSc. Engineering (Elec.) Electronics",
    startDate: "1981",
    endDate: "1985",
  },
];

const Education = () => {
  let uniTable = "Looks like you are uneducated ... nothing to show here ;-)";

  uniTable =
    educationArray.length > 0
      ? educationArray.map((item) =>
          item.show ? (
            <Card className="qualCard" key={item.id}>
              <img src={item.logoUrl} alt="" />
              <Card.Body>
                <Card.Title as="h3">{item.qualification}</Card.Title>
                <Card.Text>{item.name}</Card.Text>
                <Card.Text>{`Graduated ${item.endDate}`}</Card.Text>
                <Card.Link
                  href={item.websiteUrl}
                >{`View ${item.id.toUpperCase()} website`}</Card.Link>
              </Card.Body>
            </Card>
          ) : null
        )
      : null;

  return (
    <div className="education" id="education">
      {/* <h2>Qualifications</h2> */}
      <Container className="educationContainer">{uniTable}</Container>
    </div>
  );
};

export default Education;

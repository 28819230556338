import React from "react";
import "./Footer.css";
import copyrightIcon from "../../assets/icon8-copyright-80.png";

const Footer = () => {
  return (
    <div className="footerContainer">
      <div className="copyright">
        {/* <span className="far fa-copyright"></span> */}
        <img src={copyrightIcon} alt="" />
        <span> Bateleur Business Consulting Australasia 2014</span>
      </div>

      <a href="http://www.icons8.com">Icons and illustrations by icons8</a>
    </div>
  );
};

export default Footer;
